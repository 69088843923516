import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-airportcountrysearchresults',
  templateUrl: './airportcountrysearchresults.component.html',
  styleUrls: ['./airportcountrysearchresults.component.css']
})
export class AirportcountrysearchresultsComponent implements OnInit {
  countries: [];
  airports: [];
  constructor() { }

  ngOnInit() {
  }

}
