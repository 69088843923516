import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-software-dvelopment',
  templateUrl: './software-dvelopment.component.html',
  styleUrls: ['./software-dvelopment.component.css']
})
export class SoftwareDvelopmentComponent implements OnInit {

  constructor(private route: Router) { 
  
  }

  ngOnInit() {
   // location.reload();
  }

}
