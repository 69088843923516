import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.css']
})
export class CloudComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    // $('#cldCarousel').carousel({
    //   interval: 2000,
    //   cycle: true
    // }); 
  }

}
