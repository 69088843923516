import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { BlogComponent } from './components/blog/blog.component';
import { HomeComponent } from './components/home/home.component';
import { AirportcountrysearchresultsComponent} from './components/airportcountrysearchresults/airportcountrysearchresults.component';
import { CareersComponent } from './components/careers/careers.component';
import { ClientsComponent } from './components/clients/clients.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { InfrastructureManagementComponent } from './components/infrastructure-management/infrastructure-management.component';
import { Service2Component } from './components/service2/service2.component';
import { SoftwareConsultancyComponent } from './components/software-consultancy/software-consultancy.component';
import { SoftwareDvelopmentComponent } from './components/software-dvelopment/software-dvelopment.component';
import { SoftwareSupportComponent } from './components/software-support/software-support.component';
import { ContactFromComponent } from './components/contact-from/contact-from.component';
const routes: Routes = [
   { path: '', component: HomeComponent },
   { path: 'airportcountrysearchresults', component: AirportcountrysearchresultsComponent },
   { path: 'about', component: AboutComponent },
   { path: 'blog', component: BlogComponent },
   { path: 'careers', component: CareersComponent },
   { path: 'clients', component: ClientsComponent },
   { path: 'cloud', component: CloudComponent },
   { path: 'contactus', component: ContactusComponent },
   { path: 'infrastructureManagement', component: InfrastructureManagementComponent },
   { path: 'service2', component: Service2Component },
   { path: 'softwareConsultancy', component: SoftwareConsultancyComponent },
   { path: 'softwareDvelopment', component: SoftwareDvelopmentComponent },
   { path: 'softwareSupport', component: SoftwareSupportComponent },
];
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    AirportcountrysearchresultsComponent,
    BlogComponent,
    CareersComponent,
    ClientsComponent,
    CloudComponent,
    ContactusComponent,
    InfrastructureManagementComponent,
    Service2Component,
    SoftwareConsultancyComponent,
    SoftwareDvelopmentComponent,
    SoftwareSupportComponent,
    ContactFromComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
