import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-software-consultancy',
  templateUrl: './software-consultancy.component.html',
  styleUrls: ['./software-consultancy.component.css']
})
export class SoftwareConsultancyComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    // $('#cldCarousel').carousel({
    //   interval: 2000,
    //   cycle: true
    // }); 
  }
}
