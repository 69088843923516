import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router'
const reloadPaths: string[] = ['/', '/softwareDvelopment', '/softwareConsultancy', '/softwareSupport', '/cloud'];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'msps-new';
  path = '';
  collapseNav: boolean = false;
  constructor(private router: Router) {
    this.router.events.subscribe((e: any) => {
     if (e instanceof NavigationStart) {
       console.log(e.url);
       this.path = e.url;
       const prevPath = sessionStorage.getItem('path');
       this.collapseNav = false;
       if(prevPath && e.url !== prevPath && reloadPaths.indexOf(e.url) > -1) {
         location.reload();
       }else {
        setTimeout(() => {
          const el: any = document.getElementById('myNavbar');
          el.className = el.className.replace(' in', '');
        }, 100);
       }
       sessionStorage.setItem('path', e.url);
     }
   });
  }
}
