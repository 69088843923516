import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-support',
  templateUrl: './software-support.component.html',
  styleUrls: ['./software-support.component.css']
})
export class SoftwareSupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
